import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useCart } from "react-use-cart";
import { saveCustomer } from "../redux/reducers/appSlice";
import { useSelector } from "react-redux";
import { notifySuccess, notifyError } from "../utils/toast";
import OrderServices from "../services/OrderServices";
import useUtilsFunction from "../hooks/useUtilsFunction";
import { AppContext } from "../context/AppContext";
import StripeModal from './../components/modal/StripeModal';


function Footer() {
    const dispatch = useDispatch();
    const { customer } = useSelector((state) => state.app);
    const [loading, setLoading] = useState(false);
    const { catchError } = useUtilsFunction();
    const [orderId, setOrderId] = useState("")
    const { setCustomerModal, setBillingAddress, stripeModal, setSreipeModal } = useContext(AppContext);
    const { isEmpty, items, cartTotal, emptyCart } = useCart();



    const guestBilling = { first_name: "Guest" }

    const openOrderInNewTab = (orderId) => {
        const url = `${process.env.REACT_APP_TERMINAL_URL}?orderId=${orderId}`;

        const popupWindow = window.open(url, "_blank");

        // window.location.assign(url, '_blank', 'noopener,noreferrer');
        // var newWindow = window.open(url,"Popup", "width=700,height=800");
        if (popupWindow) {
            // popupWindow.location.href = `${process.env.REACT_APP_TERMINAL_URL}?orderId=${orderId}`;
            popupWindow.focus();
        } else {
            alert('Pop up blocked! Please enable pop-ups for this site.');
        }
    };

    const createOrder = async () => {
        if (items.find(item => item.shipping_required === true) && !customer.id) {
            setCustomerModal(true);
            setBillingAddress(true);
        } else {
            const cartItems = items.map((item) => ({
                product_id: item.id,
                quantity: item.quantity,
                meta_data: item.meta_data
            }));
            let payload = {
                payment_method: "delimpterminal",
                payment_method_title: "Terminal",
                set_paid: false,
                customer_id: customer.id || 0,
                billing: guestBilling,
                line_items: cartItems,
            };
            if (customer.id) {
                Object.assign(payload, { billing: customer.billing, shipping: customer.shipping, })
            }
            try {
                setLoading(true);
                const response = await OrderServices.createOrderApi(payload);
                const order_id = response?.id;
                setOrderId(order_id)
                setLoading(false);
                //  notifySuccess("Order created successfully");
                emptyCart();
                dispatch(saveCustomer(""));
                setBillingAddress(false);
                setSreipeModal(true)
                // openOrderInNewTab(orderId);
            } catch (error) {
                const errorMessage = catchError(error);
                setLoading(false);
                notifyError(errorMessage);
            }
        }
    };

    return (
        <>
            <footer className="bg-[#3498db] text-white p-4 fixed bottom-0 w-full z-40">
                <div className="sm:flex sm:items-center gap-10">
                    <span
                        className="flex items-center sm:mb-0 space-x-3 rtl:space-x-reverse"
                    >
                        <span className="self-center text-xl cursor-pointer font-semibold whitespace-nowrap dark:text-white">
                            {cartTotal > 0 && (
                                <span >
                                    Total ${cartTotal?.toFixed(2)}
                                </span>
                            )}
                        </span>
                    </span>
                    <ul className="flex flex-wrap items-center text-sm font-medium sm:mb-0">
                        {cartTotal > 0 && (
                            <li className="bg-[#0fb4e0] px-5 py-3 transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-gray-700">
                                <div onClick={createOrder} className="flex items-center space-x-2">
                                    {cartTotal > 0 && (
                                        <span className="text-white text-base">{loading ? "Processing" : "Checkout"}</span>
                                    )}
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </footer>

            {stripeModal &&
                <StripeModal orderId={orderId} />
            }

        </>
    );
}

export default Footer;
